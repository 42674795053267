import React, {useEffect, useState} from 'react';
import Layout from '../../components/Layout';
import {GatsbyImage, StaticImage, getImage} from 'gatsby-plugin-image';
import Lottie from 'react-lottie-player';
import SectionIntro from '../../components/SectionIntro';
import QuoteSection from '../../components/QuoteSection';
import {useStaticQuery, graphql} from 'gatsby';
import Seo from '../../components/SEO';
import {Link} from 'gatsby';

// svgs
import AwsIcon from '../../images/aws.svg';
import AzureIcon from '../../images/azure.svg';
import GithubIcon from '../../images/github.svg';
import OktaIcon from '../../images/okta.svg';
import PagerDutyIcon from '../../images/pager-duty.svg';
import StrongDM from '../../images/strongdm.svg';
import TrustleLogo from '../../images/logo-arrow.svg';
import LogoArrowWhite from '../../images/logo-arrow-white.svg';

// Background images
import {convertToBgImage} from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
//images
import iconSlideCircle from '../../images/icon-slide-circle.svg';

const SectionTwoCol = ({title, description, videoData = false, className = '', imageName = '', imagesSource = []}) => {
  const currentImage = imagesSource.filter(({node}, i) => node.name === imageName);
  const currentMedia = videoData ? (
    <Lottie className="" animationData={videoData} play loop />
  ) : (
    currentImage[0]?.node && (
      <>
        <GatsbyImage
          image={getImage(currentImage[0].node)}
          width="100%"
          alt="Screenshot Trustle App"
          className={`xl:max-w-[50vw] float-right ${className}`}
        />
      </>
    )
  );
  return (
    <div className="flex flex-col xl:flex-row text-white justify-center items-center max-w-[1920px]  xl:mx-auto pt-12  xl:py-12">
      <div className="w-full xl:w-1/2">
        <div className="relative flex flex-col mx-8 mb-8 xl:items-center1 xl:justify-center xl:max-w-md xl:mx-auto xl:mb-0">
          <div className="flex flex-col mb-4 md:flex-items-center md:mb-8 ">
            <img
              className="mr-4 mb-4 w-9 xl:w-auto lg:block xl:absolute xl:left-[-80px]"
              src={LogoArrowWhite}
              alt="logo arrow"
            />
            <div className="text-3xl font-bold ">{title}</div>
          </div>
          <div className="xl:max-w-[600px] text-lg" dangerouslySetInnerHTML={{__html: description}}></div>
        </div>
      </div>

      <div className="w-full mb-8 xl:w-1/2 xl:mb-0">{currentMedia}</div>
    </div>
  );
};

const SectionSlideCard = ({title, slideLeft = true, imagesSource = []}) => {
  const [activeImage, setActiveImage] = useState(1);

  function handleOver(index) {
    setActiveImage(index);
  }
  const activeClass = ' bg-white text-blue transition delay-50 duration-300 ease-in-out ';

  const dataCards = [
    {
      title: 'Just-in-time access',
      description: 'Easily restore access that you’re already approved for',
      imageName: 'screenList-01',
    },
    {
      title: 'Automated access removal',
      description: 'Based on time and sensitivity of the resource',
      imageName: 'screenList-02',
    },
    {
      title: 'Visibility of all accounts and access',
      description: 'Overall Trustle score and drill-downs into access. Recommendations, and risk scores',
      imageName: 'screenList-03',
    },
    {
      title: 'Audit reporting',
      description:
        'Create reports in any format of all the changes and approval chains of accesses to your cloud resources',
      imageName: 'screenList-04',
    },
  ];
  const currentImageList = imagesSource.filter(({node}, i) => node.name === dataCards[activeImage].imageName);

  return (
    <div className="w-full md:py-8 ">
      <div
        className={`flex flex-col items-center max-w-6xl mx-8 py-8 md:py-16  mt-0 mb-0 justify-between xl:mx-auto ${
          slideLeft ? 'lg:flex-row' : 'lg:flex-row-reverse'
        }`}
      >
        <div className="flex items-start w-full h-full">
          <section className="flex flex-col ">
            <div className="mb-4 text-3xl font-bold text-white">{title}</div>

            {dataCards.map((item, index) => (
              <div
                role="button"
                tabIndex={index}
                className={`border rounded-xl text-white flex items-center h-[97px] p-2 xs:p-4 mb-4 ${
                  index === activeImage && activeClass
                }`}
                key={index}
                onMouseEnter={() => handleOver(index)}
                onMouseLeave={() => handleOver(index)}
              >
                <img src={iconSlideCircle} alt="icon" />
                <div className="pl-4">
                  <h3 className="text-xs font-bold xs:text-sm">{item.title}</h3>
                  <p className="text-xs xs:text-sm">{item.description}</p>
                </div>
              </div>
            ))}
          </section>
        </div>

        <div className="h-full w-full xl:min-h-[650px]">
          {currentImageList[0]?.node && (
            <GatsbyImage
              image={getImage(currentImageList[0].node)}
              width="100%"
              alt="Screenshot Trustle App"
              className="xl:max-w-[620px]"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const SectionTwoContent = ({title, description, showBtn = false}) => {
  return (
    <div className="w-full md:py-8 ">
      <div className="flex flex-col flex-col-reverse items-center justify-between max-w-6xl py-8 mx-8 mt-0 mb-0 lg:flex-row xl:mx-auto">
        <div className="flex flex-wrap justify-center max-w-3xl mx-auto md:justify-start md:w-1/2">
          {[
            <img src={AwsIcon} alt="aws" width={60} />,
            <StaticImage src="../../images/google.png" alt="google" width={50} quality={100} />,
            <img src={GithubIcon} alt="github" width={50} />,
            <img src={StrongDM} alt="strongdm" width={60} />,
            <img src={OktaIcon} alt="okta" width={60} />,
            <img src={AzureIcon} alt="azure" width={50} />,
            <img src={PagerDutyIcon} alt="pager duty" width={50} />,
            <img src={TrustleLogo} alt="trustle" width={70} />,
          ].map((img, index) => (
            <div
              className="shadow-2xl rounded-2xl p-2 sm:p-2 m-2 sm:m-2 h-[120px] w-[120px] flex justify-center items-center bg-white"
              key={index}
            >
              {img}
            </div>
          ))}
        </div>
        <div className="flex items-start mb-8 md:w-1/2 lg:mb-0">
          <img className="hidden mr-4 lg:block" src={LogoArrowWhite} alt="logo arrow" />
          <section className="flex flex-col text-white">
            <div className="text-3xl font-bold sm:mb-2">{title}</div>
            <h2 className="max-w-sm pb-8 text-lg leading-7">{description}</h2>
            {showBtn && (
              <Link to="/product/integrations" className="">
                <div className="bg-[#0091FF] hover:bg-[#0067C4] transition duration-25 ease-in-out active:bg-[#00549F] text-white rounded-3xl drop-shadow-lg font-medium md:min-w-max lg:px-12 py-2 w-full lg:text-lg z-10 px-2 py-2 mb-1 md:py1 text-center md:max-w-[250px] ">
                  See all Integrations
                </div>
              </Link>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

const ProductPage = ({location}) => {
  useEffect(() => {
    location?.state?.scrollY && window.scroll(0, location?.state?.scrollY);
  }, [location?.state?.scrollY]);

  const dataImages = useStaticQuery(graphql`
    query {
      allFile(filter: {absolutePath: {regex: "/product/"}, extension: {regex: "/(jpg)|(png)|(jpeg)/"}}) {
        edges {
          node {
            name
            relativePath
            base
            childImageSharp {
              gatsbyImageData(width: 1140, layout: CONSTRAINED, placeholder: BLURRED, quality: 100, formats: AUTO)
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      placeholderImage: file(relativePath: {eq: "background-home-desktop-section2.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  const imagesSource = dataImages?.allFile?.edges;

  const {placeholderImage} = dataImages;

  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);

  return (
    <Layout
      seo={
        <Seo
          title="What We Do | Trustle"
          description="Needs-based access control. Continuously clean up permissions, enable multi-cloud privileged access, and improve and accelerate compliance. "
        />
      }
    >
      <section className="overflow-hidden transform">
        <div className="relative inset-0 md:bg-cover bg-cover bg-gradient-to-b from-[#002761] via-[#009DDC] to-white">
          <div className="flex flex-col py-16 md:py-30 mx-5 md:mx-8 text-blue lg:max-w-[1920px] lg:mx-auto  drop-shadow-xl">
            <SectionIntro
              color="white"
              hero="Features"
              title="Needs-based access control"
              description="Continuously clean up permissions, enable multi-cloud privileged access, and improve and accelerate compliance"
              isPageHeader
              showBtn={true}
            />
            <StaticImage
              src="../../images/placeholders/image-dashboard-01.png"
              className="m-auto max-w-[1360px]"
              alt="trustle dashboard"
              quality={100}
            />
          </div>
        </div>
      </section>
      <section className="bg-cover pb-8 overflow-hidden  bg-gradient-to-b from-[#01093C] via-[#002761] to-[#01093C]">
        <SectionTwoCol
          title="Securing the cloud environment"
          description="Trustle uses a unique blend of pragmatic and programmatic approaches to secure cloud systems. To support
              audit and regulatory requirements, Trustle provides approval workflows. For ongoing access activities,
              Trustle uses a combination approval status, risk score, and policy to determine the appropriate action. Of
              course, all of this activity is available as a history for certification and audit."
          imageName="screenshot-01"
          imagesSource={imagesSource}
        />
        <SectionTwoCol
          title="Cloud-native provisioning"
          description="Traditional provisioning products rely on approval & re-certification workflows, which often create more work with little security benefit. Trustle automatically identifies and removes access from cloud systems when not in use, and restores them through simple requests."
          imageName="screenshot-02"
          className="relative left-14 2xl:left-0"
          imagesSource={imagesSource}
        />
        <SectionTwoCol
          title="Native integration through APIs"
          description="Authorization engines require connected applications to support their proprietary APIs to talk to it. Trustle integrates natively with each cloud system to continuously configure appropriate access for each user account."
          imageName="screenshot-03"
          imagesSource={imagesSource}
        />
        <SectionTwoCol
          title="Access when you need it, and for how long you need it"
          description={`Trustle’s policy-based + self-service model makes getting access simple for authorized users. Here are the steps:
<br><br><ul class='list-decimal ml-4'>
          <li>System owners build a catalog of resources, specifying the sensitivity of the resource</li>
<li>Team members can request access to resources</li>
<li>Depending on policy, a manager or system owner may need to approve the request and (optionally) change the default duration for access</li>
<li>The team member instantly gains access</li>
<li>Trustle removes access after it expires, but the team member can regain access upon request</li>
</ul>
<br />
Trustle also offers on-call features, so response teams can act with immediacy. Any team member on an on-call list (such as in PagerDuty) can immediately access any system and resource that is approved for that purpose. 
`}
          imageName="screenshot-04"
          imagesSource={imagesSource}
        />
        <SectionSlideCard title="Connect, compute, cleanup" imagesSource={imagesSource} />
        <SectionTwoContent
          title="Connect to the systems you use every day"
          description="Trustle connects through native APIs to keep your systems current with your access needs. We treat each system as its own particular security space, and we apply best practices, as well."
          showBtn={true}
        />

        <QuoteSection
          quote="We spend three months and over $1M on user access certifications every year. With Trustle, our work is significantly reduced."
          img=""
          name=""
          role="SVP, fixed income investing firm"
          color="white"
        />
      </section>

      <section>
        <BackgroundImage
          Tag="section"
          // Spread bgImage into BackgroundImage:
          {...bgImage}
          preserveStackingContext
          className="flex flex-col items-center w-full p-8 text-center bg-cover text-blue"
        >
          <div className="max-w-6xl m-auto mb-8 text-3xl font-bold text-center leading-11 md:leading-12 md:text-5xl">
            Access for the right user, to the right resource, for the right length of time
          </div>
          <Link
            className="self-center sm:self-auto mb-10 px-4 bg-[#0091FF] hover:bg-[#0067C4] active:bg-[#00549F] transition duration-25 ease-in-out text-white rounded-3xl shadow font-medium md:min-w-max lg:px-12 py-2 lg:text-lg"
            to="/demo"
          >
            Get Started for Free
          </Link>
        </BackgroundImage>
      </section>
    </Layout>
  );
};

export default ProductPage;
