import React from 'react';
import quoteLeft from '../images/homepage/icon-quote-left.svg';
import quoteRight from '../images/homepage/icon-quote-right.svg';

const QuoteSection = ({quote, name, role, color = 'blue'}) => {
  return (
    <>
      <div className="w-full max-w-[1560px] 2xl:max-w-[1920px]">
        <div className="flex flex-col w-full mb-8 text-white lg:mx-auto py-7 md:py-10">
          <div className="relative flex flex-col justify-between h-full">
            <div className="w-full ">
              <h2
                className={`mb-8 text-3xl md:text-5xl lg:leading-[75px] m-auto text-center text-${color} relative px-5 flex items-start`}
              >
                {' '}
                <img src={quoteLeft} alt="quote" className="w-10 md:w-20 xl:w-24 2xl:w-auto " />
                <div className="flex items-end justify-end">
                  <div className="py-4 md:py-8 2xl:py-16">{quote}</div>
                  <img src={quoteRight} alt="quote" className="w-10 md:w-20 xl:w-24 2xl:w-auto " />
                </div>
              </h2>

              <div className="text-sm text-center">
                <p className={`text-${color} font-bold`}>{name}</p>
                <p className={`text-${color} font-bold`}>{role}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuoteSection;
